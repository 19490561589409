.inputFile::file-selector-button {
    border: none;
    background-color: #23242e;
    transition: 3s;
    color: white;
}

.inputFile::file-selector-button:hover {
    background-color: #30313d;
    color: black;
}

.MuiOutlinedInput-root {
    border-radius: 0.25rem !important;
}
