html {
    font-size: 110%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoom-container {
    width: 100%;
    height: 100vh;
}

.zoom-sm, .zoom-md, .zoom-lg {
    overflow: hidden;
}
  
.zoom-sm {
    transform: scale(0.67);
    transform-origin: 0 0;
    width: 149.25%; 
    height: 149.25%;
}
  
.zoom-md {
    transform: scale(0.75);
    transform-origin: 0 0;
    width: 133.33%;
    height: 133.33%;
}
  
.zoom-lg {
    transform: scale(1);
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}

.table-light-theme tbody tr:nth-child(even) {
    background-color: #dedee3;
}

.table-light-theme tbody tr:nth-child(even):hover {
    background-color: #cacace;
}

.table-dark-theme tbody tr:nth-child(even) {
    background-color: #444444;
}

.table-dark-theme tbody tr:nth-child(even):hover {
    background-color: #4b4b4b;
}

.version-date {
    font-size: 20px;
}
