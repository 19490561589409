.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin-wrapper .layout {
  min-width: unset;
}

.admin-wrapper #main-content {
  overflow: hidden;
}

.list-admin-wrapper .MuiPaper-root {
  overflow-x: auto;
}

.box-shadow {
  box-shadow: 6px 5px 10px black;
}

.custom-title {
  /*border: 1px solid #e0e0e3;*/
  background-clip: padding-box;
  width: fit-content;
  /*border-radius: 25% 10%;*/
}

.fit-content {
  width: fit-content;
  height: fit-content;
}

.link-decoration {
  text-decoration: none;
  color: white;
}

.link-decoration:hover {
  text-decoration: none;
  color: white;
}

.list-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.chip {
  padding: 5px 10px;
  background-color: #F5F2F2;
  border-radius: 6px;
  margin: 7px;
  text-align: center;
}

.border {
  border: 1px solid grey !important;
}

.appbar-logo {
  width: 50px;
}

.vertical-line {
  width: 0;
  border-left: 1px solid lightgrey;
  margin-top: 100px;
}

.hr-records {
  border-bottom: 4px solid grey;
  margin: 5px 0;
}

.hr-second {
  border-bottom: 2px solid grey;
  margin: 25px 0 30px;
}

.MuiDataGrid-columnHeader:focus-within, 
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus, 
.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiTabs-indicator {
  display: none;
}

.MuiTab-root.Mui-selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(79, 60, 201);
}

.MuiInputAdornment-positionStart {
  margin-right: 0px !important;
}

.highlighted-row {
  background-color: rgba(0, 0, 0, 0.04);
}

.toolbar-style {
  position: absolute !important;
  left: 0px;
  right: 0px;
  z-index: 3;
  color: rgb(79, 60, 201);
  justify-content: space-between;
  background-color: rgb(226, 223, 246);
  min-height: 55px !important;
  height: 55px;
  transform: translateY(-48px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.RaSidebar-fixed {
  height: 100% !important;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.custom-input-width {
  width: 600px;
}

.input-width-xs {
  width: 150px;
}

.input-width-sm {
  width: 200px;
}

.input-width-md {
  width: 300px;
}

.input-width-lg {
  width: 450px;
}

.RaList-main {
  display: flex;
  width: 100%;
}

.datagrid-content .RaDatagrid-tableWrapper {
  overflow-x: auto;
  transform: rotateX(180deg);
}

.datagrid-content .RaDatagrid-tableWrapper table {
  transform: inherit;
}

.custom-tooltip .MuiTooltip-tooltip {
  max-width: none;
  width: 600px;
}

.action-tooltip .MuiTooltip-tooltip {
  max-width: none;
  width: 300px;
}
